//将拦截器整体导入
import request from '@/store/Interceptor.js'//导入已经写好的拦截器
 
// 封装所有的API接口
 
// export function Login(params){
//   console.log(params)
//   return request({
//     url:'/administrator/login',
//     method :'post',
//     params:params,
//   })
// }
 
// export function getRoles(params={}){
//   return request({
//     url:'/Roles/select',
//     method :'post',
//     params:params,
//   })
// }

// 获取渠道信息
export function getClientConfig(){
    return request({
        url:'/client/public/get_client_config',
        method:'post',
    })
}

// 轮播接口
export function getCarousel(){
    return request({
        url:'/client/public/get_home_config',
        method:'post',
    })
}

//  获取游戏列表
export function getGameList(data){
    return request({
        url:'/client/game/get_game_list',
        method:'post',
        data
    })
}

// 获取历史游戏
export function getgGameHistoryList(data){
    return request({
        url:'/client/user/get_game_history_list',
        method:'post',
        data
    })
}

//待下架游戏列表接口
export function getRemoveGameList(){
    return request({
        url:'/client/game/get_is_unde_game_list',
        method:'post',
    })
}

//  获取游戏分类
export function getGameClass(){
    return request({
        url:'/client/game/get_classify_list',
        method:'post',
    })
}

// 获取游戏详情页信息
export function getGameDetails(params){
    return request({
        url:'/client/game/get_game_data',
        method:'post',
        data:params
    })
}

//游戏列表搜索功能
export function searchGameList(data){
    return request({
        url:'/client/game/get_game_list',
        method:'post',
        data
    })
}
// 手机号登录
export function mobileLogin(data){
    return request({
        url:'/client/auth/to_mobile_login',
        method:'post',
        data
    })
}

// 登录二维码
export function loginCode(){
    return request({
        url:'/client/auth/to_thirdparty_login',
        method:'post',
    })
}

// 登录状态轮巡
export function loginStatusPolling(data){
    return request({
        url:'/client/auth/verify_thirdparty_status',
        method:'post',
        data
    })
}
//获取gameclass组件的分类
export function getGameClassList(){
    return request({
        url:'/client/game/get_column_list',
        method:'post',
    })
}

// 获取用户信息
export function getUserInformation(){
    return request({
        url:'/client/user/get_user_info',
        method:'post',
    })
}

// 实名认证接口
export function realName(data){
    return request({
        url:'/client/user/to_user_idcard',
        method:'post',
        data
    })
}

// 获取用户成长值信息
export function getUserGrowth(){
    return request({
        url:'/client/user/get_user_growt_data',
        method:'post',
    })
}

// 获取用户储值信息
export function getUserStored(){
    return request({
        url:'/client/user/get_user_stored_data',
        method:'post',
    })
}

// 获取用户积分信息
export function  getUserPoint(){
    return request({
        url:'/client/user/get_user_integral_data',
        method:'post',
    })
}

//绑定手机号
export function bindPhone(data){
    return request({
        url:'/client/user/binding_mobile',
        method:'post',
        data
    })
}

// 获取用户储值列表
export function getUserStoredValueList(data){
    return request({
        url:'/client/user/get_user_stored_list',
        method:'post',
        data
    })
}

//获取用户成长值列表
export function getUserGrowthValueList(){
    return request({
        url:'/client/user/get_user_growt_list',
        method:'post',
    })
}

//获取用户积分列表
export function getUserPointsList(){
    return request({
        url:'/client/user/get_user_integral_list',
        method:'post',
    })
}

//用户注册接口
export function userRegistration(data){
    return request({
        url:'/client/auth/to_account_register',
        method:'post',
        data
    })
}

//账号登录接口
export function accountLogin(data){
    return request({
        url:'/client/auth/to_account_login',
        method:'post',
        data
    })
}

// 修改密码接口
export function modifyPassword(data){
    return request({
        url:'/client/user/modify_password',
        method:'post',
        data
    })
}

//忘记密码接口
export function forgotPassword(data){
    return request({
        url:'/client/auth/forget_password',
        method:'post',
        data
    })
}

// 签到接口
export function toSigin(){
    return request({
        url:'/client/user/to_user_sigin',
        method:'post',
    })
}

//询问接口（是否拥有86账号）
export function existence(data){
    return request({
        url:'/client/auth/existence_not',
        method:'post',
        data
    })
}

//询问二维码接口
export function askQrcode(data){
    return request({
        url:'/client/auth/Binding',
        method:'post',
        data
    })
} 

//轮巡询问二维码接口
export function checkWxqrBinding(data){
    return request({
        url:'/client/auth/checkwxqrbinding',
        method:'post',
        data
    })
}

// 获取用户是否合并(37/81)
export function getUserMerge(){
    return request({
        url:'/client/user/get_is_merge',
        method:'post',
    })
} 

// 账号密码合并(37/81)
export function accountMerge(data){
    return request({
        url:'/client/auth/merge37',
        method:'post',
        data
    })
} 

//查询签到状态
export function getUserSigin(){
    return request({
        url:'/client/user/get_user_sigin',
        method:'post',
    })
}

// 获取支付可用列表
export function getPaymentAvailableList(){
    return request({
        url:'/client/pay/get_pay_use_list',
        method:'post',
    })
}

//获取充值实际金额接口
export function getRechargeAmount(data){
    return request({
        url:'/client/pay/recharge_amount',
        method:'post',
        data
    })
}

//发起支付-充值订单
export function initiatePayment(data){
    return request({
        url:'/client/pay/create_recharge',
        method:'post',
        data
    })
}

//校验订单状态,轮巡接口
export function verifyOrderStatus(data){
    return request({
        url:'/client/pay/verify_order_status',
        method:'post',
        data
    })
}

// 获取用户积分兑换列表
export function getPointsRedemptionList(data){
    return request({
        url:'/client/user/get_reissue_integral_list',
        method:'post',
        data
    })
}

//会员储值兑换记录列表
export function getReissueStoredList(data){
    return request({
        url:'/client/user/get_reissue_stored_list',
        method:'post',
        data
    })
}

//会员充值记录
export function memberRechargeRecord(data){
    return request({
        url:'/client/pay/get_order_list',
        method:'post',
        data
    })
}

//玩过的游戏
export function gamesPlayed(data){
    return request({
        url:'/client/user/get_reissue_game_list',
        method:'post',
        data
    })
}

//获取游戏货币列表
export function getGameCurrencyList(data){
    return request({
        url:'/client/game/get_partition_list',
        method:'post',
        data
    })
}

// 补发
export function supplyAgain(data){
    return request({
        url:'/client/user/get_game_reissue',
        method:'post',
        data
    })
}

//会员等级福利配置
export function memberLvBenefits(data){
    return request({
        url:'/client/user/get_growth_config',
        method:'post',
        data
    })
}

//文章分类列表
export function articleClassificationList(){
    return request({
        url:'/client/article/get_classify_array',
        method:'post',
    })
}

//获取文章列表
export function getArticleList(data){
    return request({
        url:'/client/article/get_article_list',
        method:'post',
        data
    })
}

//获取文章数据
export function getArticleData(data){
    return request({
        url:'/client/article/get_article_data',
        method:'post',
        data
    })
}

//短信验证码
export function messageVerification(data){
    return request({
        url:'/client/public/get_sms_code',
        method:'post',
        data
    })
}


// 获取积分商城列表
export function getProductList(data){
    return request({
        url:'/client/product/get_product_list',
        method:'post',
        data
    })
}

// 获取商城商品详情
export function getProductData(data){
    return request({
        url:'/client/product/get_product_data',
        method:'post',
        data
    })
}

//收货地址列表
export function getAddressList(data){
    return request({
        url:'/client/product/address_list',
        method:'post',
        data
    })
}

//获取用户灵符
export function getUserLingfuData(data){
    return request({
        url:'/client/user/get_user_lingfu_data',
        method:'post',
        data
    })
}

//添加收货地址
export function createUserAddress(data){
    return request({
        url:'/client/product/create_user_address',
        method:'post',
        data
    })
}

//设为默认地址
export function editAddrssData(data){
    return request({
        url:'/client/product/edit_addrss_data',
        method:'post',
        data
    })
}

//修改地址
export function editUserAddress(data){
    return request({
        url:'/client/product/edit_user_address',
        method:'post',
        data
    })
}

//删除地址接口
export function delAddress(data){
    return request({
        url:'/client/product/del_address',
        method:'post',
        data
    })
}

//商品兑换接口
export function createProductOrder(data){
    return request({
        url:'/client/product/create_product_order',
        method:'post',
        data
    })
}
// 灵符记录
export function lingfuRecords(data){
    return request({
        url:'/client/user/get_user_lingfu_list',
        method:'post',
        data
    })
}

//兑换记录
export function exchangeRecords(data){
    return request({
        url:'/client/product/product_order_list',
        method:'post',
        data
    })
}

// 获取连续充值活动
export function getLianxuRecharge(){
    return request({
        url:'/client/user/hd_lianxu_recharge',
        method:'post',
    })
}

// 获取今日充值
export function getTodayRecharge(){
    return request({
        url:'/client/pay/today_recharge',
        method:'post',
    })
}

//物料接口
export function getMaterialData(){
    return request({
        url:'/client/public/get_home_config',
        method:'post',
    })
}

// 绑定账号接口
export function createUser(data){
    return request({
        url:'/client/user/to_account_reg',
        method:'post',
        data
    })
}

// 文本流
export function bloue(data){
    return request({
        url:'media/public/files?code='+data,
        method:'get',
    })
}

// 转盘活动接口
// 转盘点击事件接口
export function playTurntable(data){
    return request({
        url:'/client/user/hd_receive',
        method:'post',
        data
    })
}

//获取抽奖次数
export function getPlaytimes(data){
    return request({
        url:'/client/user/hd_information',
        method:'post',
        data
    })
}
// |通关图鉴接口
export function getMapList(){
    return request({
        url:'/client/map/get_map_list',
        method:'post',
    })
}

// |通关图鉴开宝箱
export function openMapBox(data){
    return request({
        url:'/client/map/open_map_box',
        method:'post',
        data
    })
}

// 通关条件列表
export function atlasList(data){
    return request({
        url:'/client/map/atlas_list',
        method:'post',
        data
    })
}

// 安全密码验证
export function adminLogin(data){
    return request({
        url:'/client/auth/admin_login',
        method:'post',
        data
    })
}

// 商品兑换须知
export function productArticle(data){
    return request({
        url:'/client/product/product_article',
        method:'post',
        data
    })
}

// 返利活动
// 返利活动信息接口
export function hdDanoInfo(){
    return request({
        url:'/client/user/hd_dano_info',
        method:'get',
    })
}
//  领取接口
export function hdDanoLeader(){
    return request({
        url:'/client/user/hd_Dano',
        method:'post',
    })
}

// 参与通关接口
export function userAddMap(data){
    return request({
        url:'/client/map/user_add_map',
        method:'post',
        data
    })
}

// 包服卡列表接口
export function getCardList(){
    return request({
        url:'/client/card/get_card_list',
        method:'post',
    })
}

// 包服卡支付接口
export function createCardCharge(data){
    return request({
        url:'/client/pay/create_card_charge',
        method:'post',
        data
    })
}

// 包服卡签到查询接口
export function getCardSigin(){
    return request({
        url:'/client/user/get_user_card_sigin',
        method:'post',
    })
}

// 包服卡立即领取接口
export function toCardSigin(){
    return request({
        url:'/client/user/to_user_card_sigin',
        method:'post',
    })
}

// 用户卡牌列表
export function getUserCard(){
    return request({
        url:'/client/card/get_user_card_list',
        method:'post',
    })
}

//获取用户月卡点数
export function getUserYueka(){
    return request({
        url:'/client/user/get_user_yueka_data',
        method:'post',
    })
}

// 月卡记录
export function getUserYuekaList(data){
    return request({
        url:'/client/user/get_user_yueka_list',
        method:'post',
        data
    })
}

// 获取当月是否有月卡
export function selectMyUserCard(){
    return request({
        url:'/client/user/my_user_card',
        method:'post',
    })
}

//游戏评价接口（点赞点踩）
export function getEvaluation(data){
    return request({
        url:'/client/user/get_evaluation',
        method:'post',
        data
    })
}

//排行榜接口
export function getChartsData(data){
    return request({
        url:'/client/game/get_game_charts_list',
        method:'post',
        data
    })
}

// 包服卡卡列表
export function getMouthList(){
    return request({
        url:'/client/uniform/get_uniform_list',
        method:'post',
    })
}

//包服卡创建订单接口
export function createMouthCharge(data){
    return request({
        url:'/client/pay/create_uniform_charge',
        method:'post',
        data
    })
}
// 获取包服卡兑换余额信息接口
export function getMouth(){
    return request({
        url:'/client/uniform/get_uniform',
        method:'post',
    })
}

//获取包服卡兑换记录接口
export function getReissueMouthList(data){
    return request({
        url:'/client/user/get_reissue_uniform_list',
        method:'post',
        data
    })
}

// 砸金蛋活动
// 点击砸金蛋
export function doubleEgg(data){
    return request({
        url:'/client/user/double_egg',
        method:'post',
        data
    })
}

// 活动一信息接口
export function doubleEggindex(data){
    return request({
        url:'/client/user/double_egg_index',
        method:'post',
        data
    })
}

// 活动二信息接口
export function doubleEggInfo(data){
    return request({
        url:'/client/user/double_egg_info',
        method:'post',
        data
    })
}
// 重置接口
export function DoubleEggReset(data){
    return request({
        url:'/client/user/double_egg_reset',
        method:'post',
        data
    })
}

// 查询剩余粽子数量
export function userGoods(data){
    return request({
        url:'/client/user/user_goods',
        method:'post',
        data
    })
}

// 九宫格活动

//活动信息接口
export function SquaredInfo(data){
    return request({
        url:'/client/user/activity_index',
        method:'post',
        data
    })
}
// 点击抽奖接口
export function SquaredPlay(data){
    return request({
        url:'/client/user/activity',
        method:'post',
        data
    })
}
// 九宫格签到信息接口
export function SquaredGetSigin(data){
    return request({
        url:'/client/user/sign_index',
        method:'post',
        data
    })
}
// 九宫格签到领取接口
export function SquaredSigin(data){
    return request({
        url:'/client/user/activity_sign',
        method:'post',
        data
    })
}

// 九宫格签到已经领取
export function OneclickCollection(data){
    return request({
        url:'/client/user/oneclick_collection',
        method:'post',
        data
    })
}

// 获取活动信息接口
export function bobingInfo(data){
    return request({
        url:'/client/user/hd_bobing',
        method:'post',
        data
    })
}

// 获取活动信息接口
export function boBingReceive(data){
    return request({
        url:'/client/user/hd_bobing_receive',
        method:'post',
        data
    })
}

// 国庆自走棋
 //活动信息接口
 export function travelInfo(data){
    return request({
        url:'/client/user/hd_travel',
        method:'post',
        data
    })
}

//抽奖接口
export function travelReceive(data){
    return request({
        url:'/client/user/hd_travel_receive',
        method:'post',
        data
    })
}
// 双十一砸金蛋/双十二
// 点击砸金蛋
export function ElvenReceive(data){
    return request({
        url:'/client/user/hd_Double11_receive',
        method:'post',
        data
    })
}

// 活动信息接口
export function Double12(data){
    return request({
        url:'/client/user/hd_Double12',
        method:'post',
        data
    })
}
export function TwelveReceive(data){
    return request({
        url:'/client/user/hd_Double12_receive',
        method:'post',
        data
    })
}

// 活动信息接口
export function Double11(data){
    return request({
        url:'/client/user/hd_Double11',
        method:'post',
        data
    })
}
// 字兑换接口
export function CollectiveWord(data){
    return request({
        url:'/client/user/hd_collectiveWord',
        method:'post',
        data
    })
}